<template>
    <div>
        <Quote/>
        <GoBack next="Promo kodlar ro'yxati"/>
        <div class="row justify-content-center mt-3 pt-0">
            <div class="col-12 col-md-9 p-1">
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th>
                            <input
                                v-model="search.promo"
                                @keyup.enter="searchPromoInfo"
                                type="text"
                                placeholder="Nomi"
                                style="width: 70px;border-radius: 5px"
                                class="input"
                            />
                        </th>
                        <th class="d-none d-sm-table-cell">
                            <input
                                v-model="search.discount"
                                @keyup.enter="searchPromoInfo"
                                type="text"
                                placeholder="Chegirma"
                                style="width: 100px;border-radius: 5px"
                                class="input"
                            />
                        </th>
                        <th class="d-none d-xl-table-cell">
                            <input
                                v-model="search.amount"
                                @keyup.enter="searchPromoInfo"
                                type="text"
                                placeholder="Miqdori"
                                style="width: 7vw;border-radius: 5px"
                                class="input"
                            />
                        </th>
                        <th>
                            <input
                                v-model="search.courseType"
                                @keyup.enter="searchPromoInfo"
                                type="text"
                                placeholder="Kurs turi"
                                style="width: 140px;border-radius: 5px"
                                class="input"
                            />
                        </th>
                        <th class="d-none d-md-table-cell">
                            <input
                                v-model="search.until"
                                @keyup.enter="searchPromoInfo"
                                type="text"
                                placeholder="Muddati"
                                style="width: 90px;border-radius: 5px"
                                class="input"
                            />
                        </th>
                        <th>
                            <b-button
                                v-b-tooltip.hover
                                title="Izlash"
                                class="border-0 p-0 me-1"
                                style="background-color: #FFFFFF"
                            >
                                <img
                                    @click="searchPromoInfo"
                                    style="height: auto; cursor: pointer"
                                    src="../assets/search-icon.png"
                                />
                            </b-button>

                            <b-button
                                v-b-tooltip.hover
                                title="Tozalash"
                                class="border-0 p-0"
                                style="background-color: #FFFFFF"
                            >
                                <img
                                    @click="resetSaleSearchInfo"
                                    style=" height: auto; cursor: pointer;"
                                    src="../assets/eraser-icon.png"
                                >
                            </b-button>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        class=" pt-3 pb-3"
                        v-for="promo in getPromoCodes"
                        :key="promo.id"
                    >
                        <td>
                            <router-link :to="'/change-promo-code/' + promo.id" style="text-decoration: none">
                                <span class="texts hover-decoration">{{ promo.promo }}</span>
                            </router-link>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <span class="texts">{{ numberWithCommas(promo.discount / 100) }}</span>
                            <span v-if="promo.isPercent">%</span>
                            <span v-else>so'm</span>
                        </td>
                        <td class="d-none d-xl-table-cell">
                            <span class="texts">{{ promo.amount }}</span>
                        </td>
                        <td>
                             <span
                                 class="texts"
                                 v-for="(courseTypeName, index) in promo.promos"
                                 :key="index"
                             >
                                {{ courseTypeName.name }}
                            </span>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <span class="texts">{{ convertDateAnnouncement(promo.until) }}</span>
                        </td>

                        <td>
                            <router-link
                                :to="'/change-promo-code/' + promo.id"
                                class="me-1 "
                            >
                                <img src="../assets/pen.png" alt="pencil-icon">
                            </router-link>
                            <img
                                class="ms-3"
                                style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                @click="showModal(promo.id)"
                                src="../assets/trash.png"
                                alt="trash-icon"
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-3">
                <b-pagination
                    v-if="getPromoCodesTotal >= 19"
                    v-model="currentPage"
                    pills
                    align="center"
                    :per-page="20"
                    :total-rows="getPromoCodesTotal"
                    @input=" fetchPromoCodesByPage"
                    :click-handler=" fetchPromoCodesByPage"
                >
                </b-pagination>
            </div>
        </div>

        <div>
            <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ushbu promo-kodni rostdan ham o'chirmoqchimisiz?</h3>
                </div>
                <b-button
                    class="mt-3"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorModal"
                >
                    Yo'q
                </b-button>
                <b-button
                    class="mt-3 float-end p-2"
                    style="width: 100px"
                    variant="btn btn-danger"
                    size="md"
                    @click="deletePromo"
                >
                    Ha
                </b-button>
            </b-modal>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ushbu promo-kod o'chirildi</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixin/accessMixin";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {GoBack, Quote},
    data() {
        return {
            currentPage: 1,
            search: {
                promo: '',
                discount: '',
                isPercent: '',
                amount: '',
                until: '',
                courseType: [],
                page: 1
            },
            show: true,
            promoId: null
        }
    },
    computed: {
        ...mapGetters(['getCheckPromo', 'getPromoCodesTotal', 'getPromo', 'getCourseTypes', 'getPromoCodes', 'getSearchPromo']),
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchPromoCodes(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'fetchPromoCodes',
            'createPromoCode',
            'checkPromoCode',
            'fetchCourseTypes',
            'deletePromoCode',
            'searchPromo',
            ''
        ]),
        searchPromoInfo() {
            this.show = true

            this.searchPromo(this.search)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSaleSearchInfo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.searchPromoInfo()
        },
        fetchPromoCodesByPage() {
            this.$router.push('/promo-codes/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        deletePromo() {
            this.deletePromoCode(this.promoId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    this.$refs['modal-success'].show()
                    this.fetchPromoCodes(this.currentPage)
                })
        },
        showModal(id) {
            this.promoId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$refs['modal-delete'].hide()
            this.fetchPromoCodes(this.currentPage)
        },
        convertDateAnnouncement(inputFormat) {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }

            let d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
    },
    mounted() {
        this.show = true
        this.fetchCourseTypes()
        this.fetchPromoCodes(this.currentPage)
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
    padding-left: 0.5rem;
}

.input::placeholder {
    font-size: 13px;
    padding: 0.5rem;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

.texts {
    display: block;
}

@media (max-width: 450px) {
    .texts {
        word-break: break-all;
        font-size: 13px;
    }

    .input {
        font-size: 4vw;
        margin: 0;
    }
}
</style>
